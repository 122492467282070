import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import ArchiveList from "./ArchiveList";
import MediaQuery from 'react-responsive'
import SubmitButton from "./SubmitButton";
import {View} from "react-native-web";

const HomePage = () => (
    <section>
        <MediaQuery minWidth={500}>
        <div className="mainPageDiv" style={{
            backgroundColor: "black",
            width: '100%',
            height: '100%',
            top: '0',
            overflowX: 'hidden',
            overflowY: 'scroll'
        }}>
        <div style={{
            marginTop: '10px'
        }}>
        </div>
        <SpinningLogo></SpinningLogo>
        <h1 style={{
            color: 'white',
            fontSize: '7px',
            fontFamily: 'Press',
            whiteSpace: 'pre-wrap',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'left',
            marginTop: '-15px',
            width: '500px',
            lineHeight: '20px',
        }}>
            [TRAVSPRESENTS CIRCA 2023.]
        </h1>
        <img src={require('../assets/TP_crew_pic.jpg')} style={{
            width: '500px',
            justifyContent: 'center',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '-5px'
        }}/>
        <div className="numberContainer" style={{
            width: '500px',
            // border: '1px solid green',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex'
        }}>
            <img src={require('../assets/000001230007.gif')} style={{
            width: '190px',
            justifyContent: 'center',
            display: 'flex',
            marginLeft: '10px',
            marginTop: '45px',
            }}/>
            <div className="secondaryNumberContainer" style={{
                marginTop: '20px'
            }}>
                <h1 style={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'Press',
                    whiteSpace: 'pre-wrap',
                    marginTop: '45px',
                    marginLeft: '30px',
                    width: '280px',
                    lineHeight: '20px',
                    // border: '1px solid green'
                }}>TO KEEP UP TO DATE, ENTER YOUR PHONE NUMBER.{"\n\n"}YOU WILL RECEIVE TEXTS REGARDING OUR UPCOMING EVENTS</h1>
                {/* <TextInputExample></TextInputExample> */}
                <View style={{
                    borderStyle: 'dotted',
                    borderWidth: 2,
                    marginLeft: '30px',
                    backgroundColor: 'white',
                    marginTop: '20px'
                    }}>
                </View>
                <SubmitButton></SubmitButton>
            </div>
        </div>
        <div className="archiveContainer" 
            style={{
                width: '500px',
                // border: '1px solid green',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '40px',
                display: 'flex',
            }}>
            {/* <button onClick={submitNumber} 
                style={{
                    marginLeft: '30px',
                    width: '80px',
                    height: '25px',
                    border: '2px solid white',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: 'Press',
                }}>SUBMIT
            </button> */}
            <ArchiveList></ArchiveList>
            <img src={require('../assets/001036130021.gif')} style={{
                width: '220px',
                justifyContent: 'center',
                display: 'flex',
                right: '0px',
                marginLeft: '40px'
            }}/>
        </div>
        <div style={{
            marginTop: '45px'
        }}>
            <Header></Header>
        </div>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={500}>
        <div className="mainPageDiv" style={{
            backgroundColor: "black",
            width: '100%',
            height: '100%',
            top: '0',
            overflowX: 'hidden',
            overflowY: 'scroll'
        }}>
        <div style={{
            marginTop: '10px'
        }}>
            <Header></Header>
        </div>
        <SpinningLogo></SpinningLogo>
        <h1 style={{
            color: 'white',
            fontSize: '6px',
            fontFamily: 'Press',
            whiteSpace: 'pre-wrap',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'left',
            marginTop: '-25px',
            width: '345px',
            lineHeight: '20px',
        }}>
            [TRAVSPRESENTS CIRCA 2023.]
        </h1>
        <img src={require('../assets/TP_crew_pic.jpg')} style={{
            width: '345px',
            justifyContent: 'center',
            // border: '1px solid green',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '-5px'
        }}/>
        <div className="numberContainer" style={{
            width: '375px',
            // border: '1px solid green',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            marginTop: '14px'
        }}>
            <img src={require('../assets/000001230007.gif')} style={{
            width: '200px',
            height: '300px',
            justifyContent: 'center',
            display: 'flex',
            marginLeft: '-55px',
            marginTop: '34px',
            }}/>
            <div className="secondaryNumberContainer" style={{
                marginTop: '20px'
            }}>
                <h1 style={{
                    color: 'white',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    whiteSpace: 'pre-wrap',
                    marginTop: '30px',
                    marginLeft: '30px',
                    width: '200px',
                    lineHeight: '20px',
                    // border: '1px solid green'
                }}>TO KEEP UP TO DATE, ENTER YOUR PHONE NUMBER.{"\n\n"}YOU WILL RECEIVE TEXTS REGARDING OUR UPCOMING EVENTS</h1>
                {/* <TextInputExample></TextInputExample> */}
                <View style={{
                    borderStyle: 'dotted',
                    borderWidth: 2,
                    marginLeft: '30px',
                    backgroundColor: 'white',
                    marginTop: '20px',
                    width: '200px'
                    }}>
                </View>
                <SubmitButton></SubmitButton>
            </div>
        </div>
        <div className="archiveContainer" 
            style={{
                width: '500px',
                // border: '1px solid green',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '40px',
                display: 'flex',
            }}>
            <ArchiveList></ArchiveList>
            <img src={require('../assets/001036130021.gif')} style={{
                width: '220px',
                justifyContent: 'center',
                display: 'flex',
                right: '0px',
                marginLeft: '20px'
            }}/>
        </div>
        <div style={{
            marginTop: '45px'
        }}>
            <Header></Header>
        </div>
        </div>
        </MediaQuery>
    </section>
);

export default HomePage;