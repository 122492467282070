import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";

const OrderSuccessPage = () => (
    <section>
        <MediaQuery minWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                height: '50%',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <h1 style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    color: 'red',
                    fontSize: '14px',
                    textAlign: 'center',
                    fontFamily: 'Press',
                    lineHeight: '20px',
                    whiteSpace: 'pre-wrap',
                    width: '460px'
                    // border: '1px solid green'
                }}>
                    THANK YOU FOR YOUR ORDER.
                    <br></br>
                    <br></br>
                    OUR TEAM WILL SHIP YOUR ORDER IN THE FOLLOWING 1-2 WORKING DAYS.
                    WE WILL SEND YOU A CONFIRMATION EMAIL AS SOON AS YOUR PARCEL IS ON ITS WAY.
                    <br></br>
                    <br></br>
                    - THE TP TEAM
                    <br></br>
                    <br></br>
                    <br></br>
                    CLICK THE ^LOGO TO RETURN TO THE HOME PAGE.
                </h1>
            </div>
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                width: '380px',
                display: 'flex',
                height: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                // border: '1px solid green'
            }}>
                <h1 style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    color: 'red',
                    fontSize: '12px',
                    textAlign: 'center',
                    fontFamily: 'Press',
                    lineHeight: '20px',
                    whiteSpace: 'pre-wrap',
                    // width: '440px'
                    // border: '1px solid green'
                }}>
                    THANK YOU FOR YOUR ORDER.
                    <br></br>
                    <br></br>
                    OUR TEAM WILL SHIP YOUR ORDER IN THE FOLLOWING 1-2 WORKING DAYS.
                    WE WILL SEND YOU A CONFIRMATION EMAIL AS SOON AS YOUR PARCEL IS ON ITS WAY.
                    <br></br>
                    <br></br>
                    - THE TP TEAM
                    <br></br>
                    <br></br>
                    <br></br>
                    CLICK THE ^LOGO TO RETURN TO THE HOME PAGE.
                </h1>
            </div>
            </div>
        </MediaQuery>
    </section>
);

export default OrderSuccessPage;