import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";
import ProductPage from "./ProductPage";
import productImg from '../assets/glow_thumbnail_v1.png'

const GlowInTheDarkTeePage = () => (
    <section>
        <MediaQuery minWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                width: '100%',
                height: '35px',
                // border: '1px solid green',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Link to="/store">
                <button style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'Press',
                    marginBottom: '20px',
                    }}>BACK
                </button>
                </Link>
            </div>
            <ProductPage
                title="[GLOW IN THE DARK TP T-SHIRT]"
                description="THE ORIGINATOR. BUT GLOWING IN THE DARK ORIGINATOR. [REDEEM AT YOUR OWN RISK]"
                img={productImg}
                smallLink="https://buy.stripe.com/28o2933gybpK2Ri3cn"
                mediumLink="https://buy.stripe.com/9AQ7tn7wOfG0bnO28k"
                largeLink="https://buy.stripe.com/dR62937wO1PabnOcMZ"
                xlargeLink="https://buy.stripe.com/4gweVP6sK3XifE43cq"
                xxlargeLink="https://buy.stripe.com/5kA7tng3k79u1Ne3cr"
            ></ProductPage>
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                width: '100%',
                height: '35px',
                // border: '1px solid green',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '-15px'
            }}>
                <Link to="/store">
                <button style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    marginBottom: '-20px',
                    }}>BACK
                </button>
                </Link>
            </div>
            <ProductPage
                title="[THE NEW ERA BBK T-SHIRT]"
                description="THE ORIGINATOR. BUT GLOWING IN THE DARK ORIGINATOR. [REDEEM AT YOUR OWN RISK]"
                img={productImg}
                smallLink="https://buy.stripe.com/28o2933gybpK2Ri3cn"
                mediumLink="https://buy.stripe.com/9AQ7tn7wOfG0bnO28k"
                largeLink="https://buy.stripe.com/dR62937wO1PabnOcMZ"
                xlargeLink="https://buy.stripe.com/4gweVP6sK3XifE43cq"
                xxlargeLink="https://buy.stripe.com/5kA7tng3k79u1Ne3cr"
            ></ProductPage>
            </div>
        </MediaQuery>
    </section>
);

export default GlowInTheDarkTeePage;