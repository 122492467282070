import './App.css';
import HomePage from './Components/HomePage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import ArchivePage from './Components/ArchivePage';

function App() {
  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
