import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";
// import ProductPage from "./ProductPage";
import productImg from '../assets/shirt_v1.png'
import productImg2 from '../assets/glow_thumbnail_v1.png'
import productImg3 from '../assets/clash_tp_tee.png'

const Store = () => (
    <section>
        <MediaQuery minWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '15px',
                textAlign: 'center',
                fontFamily: 'Press',
                lineHeight: '30px',
                whiteSpace: 'pre-wrap'
            }}>
                STORE<br></br><span style={{color: 'red', fontSize: '12px'}}>[1] NEW ITEM ADDED</span>
            </h1>
            <div style={{
                display: 'flex',
                width: '100%',
                alignContent: 'center',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                columnGap: '100px'
                // border: '1px solid green'
            }}>
                <div style={{
                    display: 'block',
                    width: '280px',
                    // border: '1px solid green'
                }}>
                    <Link to="/clash-x-tp-tshirt">
                    <img src={productImg3} style={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        marginTop: '25px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>CLASH X TRAVSPRESENTS<br></br>T-SHIRT<br></br><span style={{color: 'red'}}>[LIMITED EDITION]</span></h1>
                </div>

                <div style={{
                    display: 'block',
                    width: '280px',
                    // border: '1px solid green'
                }}>
                    <Link to="/og-bbk-tshirt">
                    <img src={productImg} style={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        marginTop: '25px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>THE NEW ERA BBK<br></br>T-SHIRT</h1>
                </div>
                {/* <h1 style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'center',
                    fontFamily: 'Press',
                    lineHeight: '20px',
                    whiteSpace: 'pre-wrap',
                    marginTop: '20px'
                }}>^THE NEW ERA BBK T-SHIRT^<br></br>£30</h1> */}
                <div style={{
                    display: 'none',
                    width: '220px',
                    // border: '1px solid green',
                    marginLeft: '40px'
                }}>
                    <Link to="/glow-in-the-dark">
                    <img src={productImg2} style={{
                        width: '220px',
                        justifyContent: 'center',
                        display: 'flex',
                        // marginLeft: '40px',
                        // marginRight: 'auto',
                        marginTop: '25px',
                    }}/>
                    </Link>
                    <h1 style={{
                        // marginLeft: '40px',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>GLOW IN THE DARK<br></br>T-SHIRT</h1>
                    <h2 style={{
                        marginRight: 'auto',
                        color: 'red',
                        fontSize: '8px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>[LIMITED]</h2>
                </div>
            </div>
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '13px',
                textAlign: 'center',
                fontFamily: 'Press',
                lineHeight: '26px',
                whiteSpace: 'pre-wrap'
            }}>
                STORE<br></br><span style={{color: 'red', fontSize: '10px'}}>[1] NEW ITEM ADDED</span>
            </h1>
            <div>
                <Link to="/og-bbk-tshirt">
                <img src={productImg} style={{
                    width: '300px',
                    justifyContent: 'center',
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    // marginTop: '-10px',
                    // border: '1px solid green'
                }}/>
                </Link>
                <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                }}>THE NEW ERA BBK<br></br>T-SHIRT</h1>
                {/* <Link to="/glow-in-the-dark">
                <img src={productImg2} style={{
                    width: '300px',
                    justifyContent: 'center',
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '30px',
                    // border: '1px solid green'
                }}/>
                </Link>
                <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                }}>GLOW IN THE DARK<br></br>T-SHIRT</h1>
                <h2 style={{
                        marginRight: 'auto',
                        color: 'red',
                        fontSize: '8px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        paddingBottom: '40px'
                        // border: '1px solid green'
                }}>[LIMITED]</h2> */}
            </div>
            <div style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                    // border: '1px solid green'
                }}>
                    <Link to="/clash-x-tp-tshirt">
                    <img src={productImg3} style={{
                        width: '300px',
                        justifyContent: 'center',
                        display: 'flex',
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        marginTop: '25px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontFamily: 'Press',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>CLASH X TRAVSPRESENTS<br></br>T-SHIRT<br></br><span style={{color: 'red'}}>[LIMITED EDITION]</span></h1>
                </div>
            </div>
        </MediaQuery>
    </section>
);

export default Store;