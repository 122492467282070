import {React, useState, useEffect} from "react";
import "../App.css";
import MediaQuery from "react-responsive";

export default function SetComponent(props) {
    return(
    <section> 
        <MediaQuery minWidth={500}>
        <div className="thumbnail_container" style={{
            // border: '1px solid green',
            justifyContent: 'center',
            alignItems: 'center',
            width: '480px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '60px'
        }}>
            <h2 style={{
                color: 'red',
                fontSize: '8px',
                textAlign: 'center',
                fontFamily: 'Press',
                whiteSpace: 'pre-wrap',
                lineHeight: '14px',
                marginTop: '20px'
            }}>
                {props.date}
            </h2>
            <h1 style={{
                color: 'white',
                fontSize: '12px',
                textAlign: 'center',
                fontFamily: 'Press',
                lineHeight: '20px',
                whiteSpace: 'pre-wrap',
            }}>
                {props.title}
            </h1>
            <h2 style={{
                color: 'white',
                fontSize: '9px',
                textAlign: 'center',
                fontFamily: 'Press',
                whiteSpace: 'pre-wrap',
                lineHeight: '14px',
                // marginTop: '-5px'
            }}>
                {props.description}
            </h2>
            <a href={props.link} target="_blank" rel="noreferrer">
                <img src={props.img} style={{
                width: '340px',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '30px'
                }}/>
            </a>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={500}>
        <div className="thumbnail_container" style={{
            // border: '1px solid green',
            justifyContent: 'center',
            alignItems: 'center',
            width: '360px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '46px'
        }}>
            <h2 style={{
                color: 'red',
                fontSize: '7.5px',
                textAlign: 'center',
                fontFamily: 'Press',
                whiteSpace: 'pre-wrap',
                lineHeight: '14px',
                marginTop: '20px'
            }}>
                {props.date}
            </h2>
            <h1 style={{
                color: 'white',
                fontSize: '10px',
                textAlign: 'center',
                fontFamily: 'Press',
                lineHeight: '16px',
                whiteSpace: 'pre-wrap',
            }}>
                {props.title}
            </h1>
            <h2 style={{
                color: 'white',
                fontSize: '7px',
                textAlign: 'center',
                fontFamily: 'Press',
                whiteSpace: 'pre-wrap',
                lineHeight: '12px',
                // marginTop: '-5px'
            }}>
                {props.description}
            </h2>
            <a href={props.link} target="_blank" rel="noreferrer">
                <img src={props.img} style={{
                width: '340px',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '30px'
                }}/>
            </a>
        </div>
        </MediaQuery>
    </section>
    );
}