import {React} from "react";
import "../App.css";
import MediaQuery from "react-responsive";

const Header = () => (
    <section>
        <MediaQuery minWidth={500}>
        <h1 style={{
            color: 'white',
            whiteSpace: 'nowrap',
            fontSize: '8px',
            letterSpacing: '-1px',
            fontFamily: 'Press',
            top: '2px',
            position: 'absolute'
        }}>TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS</h1>
        </MediaQuery>
        <MediaQuery maxWidth={500}>
        <h1 style={{
            color: 'white',
            whiteSpace: 'nowrap',
            fontSize: '6px',
            letterSpacing: '-1px',
            fontFamily: 'Press',
            top: '2px',
            position: 'fixed',
        }}>TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS / . * / TRAVS PRESENTS</h1>
        </MediaQuery>
    </section>
);

export default Header;