import {React} from "react";
import "../App.css";
// import logo from '../assets/TP_15x_spin-hevc-safari.mp4';
import logoChrome from '../assets/TP 15x spin-vp9-chrome.webm'
import MediaQuery from "react-responsive";
import logo from '../assets/TP_spinning_logo.gif'

const SpinningLogo = () => (
    <section>
        <MediaQuery minWidth={500}>
            <img src={logo} style={{
                width: '360px',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '15px',
                marginBottom: '15px'
            }}>
            </img>
        </MediaQuery>
        <MediaQuery maxWidth={500}>
            {/* <video class="spinningLogo" autoPlay loop muted playsinline style={{
                width: '375px',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '-25px',
                // border: '1px solid green',
            }}>
                <source src={logo} type='video/mp4'></source>
                <source src={logoChrome} type='video/mp4' ></source>
            </video> */}
            <img src={logo} style={{
                width: '320px',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '15px',
                marginBottom: '15px'
            }}>
            </img>
        </MediaQuery>
    </section>
);

export default SpinningLogo;