import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ArchivePage from './Components/ArchivePage';
// import HomePage from './Components/HomePage';
import ScrollToTop from './Components/ScrollToTop';
import PhotoArchive from './Components/PhotoArchive';
import Store from './Components/Store';
import OrderSuccessPage from './Components/OrderSuccessPage';
import PlainTeePage from './Components/PlainTeePage';
import GlowInTheDarkTeePage from './Components/GlowInTheDarkTeePage';
import ClashTeePage from './Components/ClashTee';

const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render((
    <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={ <App/> }>
        </Route>
        <Route path="/set-archive" element={ <ArchivePage/> }>
        </Route>
        <Route path="/photo-archive" element={ <PhotoArchive/> }>
        </Route>
        <Route path="/store" element={ <Store/> }>
        </Route>
        <Route path="/og-bbk-tshirt" element={ <PlainTeePage/> }>
        </Route>
        <Route path="/glow-in-the-dark" element={ <GlowInTheDarkTeePage/> }>
        </Route>
        <Route path="/clash-x-tp-tshirt" element={ <ClashTeePage/> }>
        </Route>
        <Route path="/order-success" element={ <OrderSuccessPage/> }>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
  ))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
