import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";
import { PhotoAlbum } from "react-photo-album";

const photos = [
    { src: require("../assets/001036130011.gif"), width: 400, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036120003.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036120004.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036120005.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036120007.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036120009.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036120010.png'), width: 450, height: 350 },
    { src: require('../assets/anniversary_3D_pics/001036120011.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036120023.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130003.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130004.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130008.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130011.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130015.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130016.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130019.gif'), width: 450, height: 600 },
    { src: require('../assets/anniversary_3D_pics/001036130021.gif'), width: 450, height: 600 },
];

const PhotoArchive = () => (
    <section>
        <MediaQuery minWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '110%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '20px',
                textAlign: 'center',
                fontFamily: 'Press',
                lineHeight: '20px',
                whiteSpace: 'pre-wrap',
                marginTop: '-20px',
                marginBottom: '-0px'
            }}>
                COMING SOON
            </h1>
            {/* <div style={{
                width: '400px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '45px'
            }}>
                <PhotoAlbum layout="rows" photos={photos} style={{
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}></PhotoAlbum>
            </div> */}
            <img src={require('../assets/001036130011.gif')} style={{
                width: '400px',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '45px',
            }}/>
            {/* <img src={require('../assets/anniversary_3D_pics/001036120001.gif')} style={{
                width: '400px',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '45px',
            }}/> */}
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '20px',
                textAlign: 'center',
                fontFamily: 'Press',
                lineHeight: '20px',
                whiteSpace: 'pre-wrap',
                marginTop: '10px',
                marginBottom: '-0px'
            }}>
                COMING SOON
            </h1>
            <img src={require('../assets/001036130011.gif')} style={{
                width: '300px',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '45px',
            }}/>
            </div>
        </MediaQuery>
    </section>
);

export default PhotoArchive;