import {React} from "react";
import "../App.css";
import '../index.css';
import { Link } from "react-router-dom";
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import SetComponent from "./SetComponent";
import thumbnail1 from "../assets/vid1_thumbnail.jpeg";
import thumbnail2 from "../assets/vid2_thumbnail.jpeg";
import thumbnail3 from "../assets/vid3_thumbnail.jpg";
import thumbnail4 from "../assets/vid4_thumbnail.jpg";
import thumbnail5 from "../assets/vid5_thumbail.jpg";
import thumbnail6 from "../assets/vid6_thumbnail.jpg";

const ArchivePage = () => (
    <section>
        <MediaQuery minWidth={500}>
        <div className="mainPageDiv" style={{
            backgroundColor: "black",
            width: '100%',
            height: '100%',
            top: '0',
            overflowX: 'hidden',
            overflowY: 'scroll'
        }}>
        <div style={{
            marginTop: '10px'
        }}>
            <Header></Header>
        </div>
        <Link to="/">
        <SpinningLogo></SpinningLogo>
        </Link>
        <h1 style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            color: 'white',
            fontSize: '20px',
            textAlign: 'center',
            fontFamily: 'Press',
            lineHeight: '20px',
            whiteSpace: 'pre-wrap',
            marginTop: '-20px',
            marginBottom: '-0px'
        }}>
            SET ARCHIVE
        </h1>
        <SetComponent 
            title="Travs Presents: General Courts + Friends (Featuring Duppy, Kruz Leone, Razor, Renz + More)"
            description="Travs Presents brings on General Courts and Friends featuring Duppy, Sbk, Renz, Razor, Kruz Leone, Shakes, Logan and Jososick."
            img={thumbnail6}
            date="june 2nd 2023"
            link="https://www.youtube.com/watch?v=VrIDWQ7BnYU">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: 1st Birthday Set (Featuring Sbk, Duppy, Shakes, Rolla, Logan + More)"
            description="Travs Presents celebrates its First Birthday. Featuring - Sbk, Duppy, Kruz Leone, Shakes, Logan, Renz, Jafro, Melvillous, Rolla, Micofcourse, Babydoom, Tcdagenius, Whackeye, Teeayy, Ezro and Jososick."
            img={thumbnail5}
            date="may 12th 2023"
            link="https://www.youtube.com/watch?v=xfY558As1dM&t=31s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: M.I.C, Duppy, Melvillous, Logan, Jafro, Namesbliss + More"
            description="Travs Presents is back with their First show of 2023. Featuring - Duppy, Renz, M.I.C, Logan, Jososick, Kruz Leone, Jafro, Namesbliss, Melvillous, Whackeye + Imperial"
            img={thumbnail4}
            date="april 7th 2023"
            link="https://www.youtube.com/watch?v=pemL1jVN0qw&t=202s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: End of Year Set (2022) w/ Renz, Jawnino, JPntn, Kibo, Micofcourse + More"
            description="The Travs Presents team took over the 1 Lower Clapton Road gallery for a legendary end of year set - featuring artists such as Renz, Jawnino, JPntn, Kibo, Micofcourse + many more holding down the fort on mic duties."
            img={thumbnail3}
            date="february 6th 2023"
            link="https://www.youtube.com/watch?v=18ge3BMAwEQ&t=890s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: Duppy + Friends (Featuring Mez, Kruz Leone, Jammz + More)"
            description="Duppy Comes through to Travs Presents with Kruz Leone, Mez, Jammz, JoSoSick And Natty."
            img={thumbnail2}
            date="january 5th 2023"
            link="https://www.youtube.com/watch?v=j778MrQZVg4&t=72s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: KIBO + Friends"
            description="Kibo comes and shuts down Travs Presents"
            img={thumbnail1}
            date="november 14th 2022"
            link="https://www.youtube.com/watch?v=XoNwgoEcszI&t=311s">
        </SetComponent>
        <div style={{
            marginTop: '75px'
        }}>
            <Header></Header>
        </div>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={500}>
        <div className="mainPageDiv" style={{
            backgroundColor: "black",
            width: '100%',
            height: '100%',
            top: '0',
            overflowX: 'hidden',
            overflowY: 'scroll',
        }}>
        <div style={{
            marginTop: '10px'
        }}>
            <Header></Header>
        </div>
        <Link to="/">
        <SpinningLogo></SpinningLogo>
        </Link>
        <h1 style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            color: 'white',
            fontSize: '20px',
            textAlign: 'center',
            fontFamily: 'Press',
            lineHeight: '20px',
            whiteSpace: 'pre-wrap',
            marginTop: '-20px',
            marginBottom: '-0px'
        }}>
            SET ARCHIVE
        </h1>
        <SetComponent 
            title="Travs Presents: General Courts + Friends (Featuring Duppy, Kruz Leone, Razor, Renz + More)"
            description="Travs Presents brings on General Courts and Friends featuring Duppy, Sbk, Renz, Razor, Kruz Leone, Shakes, Logan and Jososick."
            img={thumbnail6}
            date="june 2nd 2023"
            link="https://www.youtube.com/watch?v=VrIDWQ7BnYU">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: 1st Birthday Set (Featuring Sbk, Duppy, Shakes, Rolla, Logan + More)"
            description="Travs Presents celebrates its First Birthday. Featuring - Sbk, Duppy, Kruz Leone, Shakes, Logan, Renz, Jafro, Melvillous, Rolla, Micofcourse, Babydoom, Tcdagenius, Whackeye, Teeayy, Ezro and Jososick."
            img={thumbnail5}
            date="may 12th 2023"
            link="https://www.youtube.com/watch?v=xfY558As1dM&t=31s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: M.I.C, Duppy, Melvillous, Logan, Jafro, Namesbliss + More"
            description="Travs Presents is back with their First show of 2023. Featuring - Duppy, Renz, M.I.C, Logan, Jososick, Kruz Leone, Jafro, Namesbliss, Melvillous, Whackeye + Imperial"
            img={thumbnail4}
            date="april 7th 2023"
            link="https://www.youtube.com/watch?v=pemL1jVN0qw&t=202s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: End of Year Set (2022) w/ Renz, Jawnino, JPntn, Kibo, Micofcourse + More"
            description="The Travs Presents team took over the 1 Lower Clapton Road gallery for a legendary end of year set - featuring artists such as Renz, Jawnino, JPntn, Kibo, Micofcourse + many more holding down the fort on mic duties."
            img={thumbnail3}
            date="february 6th 2023"
            link="https://www.youtube.com/watch?v=18ge3BMAwEQ&t=890s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: Duppy + Friends (Featuring Mez, Kruz Leone, Jammz + More)"
            description="Duppy Comes through to Travs Presents with Kruz Leone, Mez, Jammz, JoSoSick And Natty."
            img={thumbnail2}
            date="january 5th 2023"
            link="https://www.youtube.com/watch?v=j778MrQZVg4&t=72s">
        </SetComponent>
        <SetComponent 
            title="Travs Presents: KIBO + Friends"
            description="Kibo comes and shuts down Travs Presents"
            img={thumbnail1}
            date="november 14th 2022"
            link="https://www.youtube.com/watch?v=XoNwgoEcszI&t=311s">
        </SetComponent>
        <div style={{
            display: 'flex',
            height: '60px',
            width: '100%'
        }}>
        </div>
        </div>
        <div style={{
            // marginTop: '-30px'
        }}>
            <Header></Header>
        </div>
        </MediaQuery>
    </section>
);

function submitNumber() {
    
}

export default ArchivePage;