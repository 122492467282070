import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";
import ProductPage from "./ProductPage";
import productImg from '../assets/shirt_v1.png'

const PlainTeePage = () => (
    <section>
        <MediaQuery minWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                width: '100%',
                height: '35px',
                // border: '1px solid green',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Link to="/store">
                <button style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'Press',
                    marginBottom: '20px',
                    }}>BACK
                </button>
                </Link>
            </div>
            <ProductPage
                title="[THE NEW ERA BBK T-SHIRT]"
                description="THE ORIGINATOR. SPECIAL OFFER: BUY 2 AND GET AN N WORD PASS. [REDEEM AT YOUR OWN RISK]"
                img={productImg}
                smallLink="https://buy.stripe.com/28o7tncR81Pa77y4gq"
                mediumLink="https://buy.stripe.com/9AQ4hbeZgdxSbnOeV3"
                largeLink="https://buy.stripe.com/3cs3d77wO3Xi4Zq9AI"
                xlargeLink="https://buy.stripe.com/8wM5lf5oGbpKgI87sz"
                xxlargeLink="https://buy.stripe.com/3cs5lf8AS3Xi4Zq7sy"
            ></ProductPage>
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                width: '100%',
                height: '35px',
                // border: '1px solid green',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '-15px'
            }}>
                <Link to="/store">
                <button style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    marginBottom: '-20px',
                    }}>BACK
                </button>
                </Link>
            </div>
            <ProductPage
                title="[THE NEW ERA BBK T-SHIRT]"
                description="THE ORIGINATOR. SPECIAL OFFER: BUY 2 AND GET AN N WORD PASS. [REDEEM AT YOUR OWN RISK]"
                img={productImg}
                smallLink="https://buy.stripe.com/28o7tncR81Pa77y4gq"
                mediumLink="https://buy.stripe.com/9AQ4hbeZgdxSbnOeV3"
                largeLink="https://buy.stripe.com/3cs3d77wO3Xi4Zq9AI"
                xlargeLink="https://buy.stripe.com/8wM5lf5oGbpKgI87sz"
                xxlargeLink="https://buy.stripe.com/3cs5lf8AS3Xi4Zq7sy"
            ></ProductPage>
            </div>
        </MediaQuery>
    </section>
);

export default PlainTeePage;