import {React, useState, useEffect} from "react";
import "../App.css";
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive'

const ArchiveList = () => (
    <section>
        <MediaQuery minWidth={500}>
        <div style={{
            // border: '1px solid green',
            width: '230px',
            marginTop: '84px'
        }}>
            <h1 style={{
                color: 'white',
                fontSize: '12px',
                fontFamily: 'Press',
                marginTop: '20px',
            }}>THE VAULTS</h1>
            <Link to="/store">
            <button onClick={submitNumber} 
                style={{
                    height: '25px',
                    backgroundColor: 'red',
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'Press',
                    marginTop: '20px'
                }}>STORE
            </button>
            </Link>
            <Link to="/set-archive">
                <button onClick={submitNumber} 
                    style={{
                        height: '25px',
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '9px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>PREVIOUS SET RECORDINGS
                </button>
            </Link>
            <a href="https://soundcloud.com/travs-presents" target="_blank" rel="noopener noreferrer">
            <button onClick={submitNumber} 
                style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'Press',
                    marginTop: '8px'
                }}>SOUNDCLOUD
            </button>
            </a>
            <Link to="/photo-archive">
            <button onClick={submitNumber} 
                style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'Press',
                    marginTop: '8px'
                }}>PHOTO ARCHIVE
            </button>
            </Link>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={500}>
        <div style={{
            // border: '1px solid green',
            width: '215px',
            marginTop: '84px',
            marginLeft: '20px'
        }}>
            <h1 style={{
                color: 'white',
                fontSize: '10px',
                fontFamily: 'Press',
                marginTop: '20px',
            }}>THE VAULTS</h1>
            <Link to="/store">
            <button onClick={submitNumber}
                style={{
                    height: '25px',
                    backgroundColor: 'red',
                    color: 'white',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    marginTop: '20px',
                }}>STORE
            </button>
            </Link>
            <Link to="/set-archive">
                <button onClick={submitNumber} 
                    style={{
                        height: '25px',
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '8px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>PREVIOUS SET RECORDINGS
                </button>
            </Link>
            <a href="https://soundcloud.com/travs-presents" target="_blank" rel="noopener noreferrer">
            <button onClick={submitNumber} 
                style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    marginTop: '8px'
                }}>SOUNDCLOUD
            </button>
            </a>
            <Link to="/photo-archive">
            <button onClick={submitNumber}
                style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    marginTop: '8px',
                }}>PHOTO ARCHIVE
            </button>
            </Link>
        </div>
        </MediaQuery>
    </section>
);

function submitNumber() {
    
}

export default ArchiveList;