import {React, useState, useEffect} from "react";
import "../App.css";
import {View} from "react-native-web";
import {TextField, Button} from '@mui/material'
import MediaQuery from 'react-responsive'
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore"; 

// add media query for better response

    const firebaseConfig = {
    apiKey: "AIzaSyDX7gkPAPdYiwOX6eiTBq_iVmr8_4VG7JM",
    authDomain: "travspresents-f3c23.firebaseapp.com",
    projectId: "travspresents-f3c23",
    storageBucket: "travspresents-f3c23.appspot.com",
    messagingSenderId: "604695859481",
    appId: "1:604695859481:web:95df446e631a706e2ff588",
    measurementId: "G-3F44GW5ND7"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);

    var textFieldValue = "+44"

export default function SubmitButton() {

    const [message, setMessage] = useState('+44');

    const [updated, setUpdated] = useState(message);

    const handleChange = (event) => {
        setMessage(event.target.value);
        console.log(event.target.value)
        textFieldValue = event.target.value
    };

    const handleClick = () => {
    // 👇 "message" stores input field value
        // setUpdated(message);
        submitNumber(textFieldValue);
    };

    return(
    <div>
        <TextField onChange={handleChange}  value={message} variant="standard" placeholder="+44 XXXXXXXXXX" 
                    InputProps={{
                    disableUnderline: true,
                    }}
                    sx={{
                        input: { 
                            color: 'white',
                            fontSize: '12px',
                            fontFamily: 'Press',
                            whiteSpace: 'pre-wrap',
                            lineHeight: '20px',
                        }
                    }}
                    style={{
                    marginLeft: '30px',
                    marginTop: '10px'
                    }}>
                </TextField>

                <View style={{
                    borderStyle: 'dotted',
                    borderWidth: 2,
                    marginLeft: '30px',
                    backgroundColor: 'white',
                    marginTop: '10px',
                    }}>
                </View>
                <h1 id="alertDiv" style={{
                    color: 'red',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    whiteSpace: 'pre-wrap',
                    lineHeight: '20px',
                    marginLeft: '30px',
                    display: 'none'
                }}>
                    ENTER A VALID NUMBER
                </h1>
                <h1 id="successDiv" style={{
                    color: 'green',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    whiteSpace: 'pre-wrap',
                    lineHeight: '20px',
                    marginLeft: '30px',
                    display: 'none'
                }}>
                    NUMBER RECIEVED
                </h1>
                <button onClick={() => { handleClick() }} style={{
                    marginLeft: '30px',
                    marginTop: '10px',
                    width: '80px',
                    height: '25px',
                    // border: '2px solid white',
                    backgroundColor: 'white',
                    color: 'black',
                    fontSize: '10px',
                    fontFamily: 'Press',
                }}>
                    SUBMIT
                </button>
    </div>
    );
};

function checkNumber(inputtxt){
    var test1 = /^\+([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{6})$/;
    var test2 = /^\d{11}$/;
    var test3 = /^\0([0-9]{6})[-. ]?([0-9]{5})$/;
    var test4 = /^\+([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/;
    // var test5 = /^\+?([0-9]{3})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/;
    var failed = document.getElementById("alertDiv");
    var success = document.getElementById("successDiv")

    failed.style.display = "none"
    success.style.display = "none"

//   if (x.style.display === "none") {
//     x.style.display = "block";
//   } else {
    // x.style.display = "none";
//   }

  if(inputtxt!='' || inputtxt!='+44 XXXXXXXXXX'){
    // console.log(inputtxt)
        if(String(inputtxt).match(test1) || String(inputtxt).match(test2) || String(inputtxt).match(test3) || String(inputtxt).match(test4)){
            failed.style.display = "none"
            success.style.display = "block"
            // x.style.display = "block"
            return true;
        } else {
            // alert('wrong format')
            success.style.display = "none"
            failed.style.display = "block"
            return false;
        }
    } else {
        success.style.display = "none"
        failed.style.display = "block"
        return
    }
}

function submitNumber(phoneNumber) {
    const db = getFirestore(app);
    var x = document.getElementById("successDiv");
    if(checkNumber(phoneNumber) == true){
    try {
        const docRef = addDoc(collection(db, "phone-numbers"), {
            phoneNumber: `${phoneNumber}`,
        });
        x.style.display = 'block'
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
    } else {
        
    }
}